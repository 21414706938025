class UtilClass {
    
    constructor() {

        this.data = [
            {
                sid: 1, 
                nav: "Breaking Bad Boy",
                name: "《Breaking Bad Boy》",
                release: "2022",
                producter: "污剧社",
                total: 5,
                star: "star5",
                desc: "高中老师Walter 研制出新型的毒品,这种毒品不仅会嗨,同时还有春药的功能。于是Walter 找来Jesse继续帮他贩卖。Jesse因为痛失前女友,一直活在悲伤中,Walter 希望能帮Jesse 走出伤痛,他们跟当地的毒贩子Nathan约好,准备在毒品市场大干一场,没想到Nathan试了他们的新型毒品之后,竟然死了...",
                trailer: {
                    web: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8",
                    mob: "https://qzvd-newblibli3.10cb1c.com/20220924/OTE5YzNhOT/105414/1080/hls/decrypt/index.m3u8"
                },
                videos: [
                    {
                        cover: "1/1",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220922/OGQ5Nzc0Zj/233218/720/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/2",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/ZTVjNzFiY2/170125/1080/hls/decrypt/index.m3u8",
                    },                  
                    {
                        cover: "1/3",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220923/NTliYjEwMD/173507/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/4",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/MjcxMmE5Mj/100137/1080/hls/decrypt/index.m3u8",
                    },
                    {
                        cover: "1/5",
                        media: "https://qzvd-newblibli3.10cb1c.com/20220924/ZmQ0MWE2Mm/095820/1080/hls/decrypt/index.m3u8",
                    },
                ]
            },
            // {
            //     sid: 2, 
            //     nav: "乡村爱情故事",
            //     name: "《乡村爱情故事》之性爱篇",
            //     release: "2022",
            //     producter: "污剧社",
            //     total: 5,
            //     star: "star5",
            //     desc: "剧情简介：离经叛道是最好的爱抚，打破陈规是最爽的亲昵，阴谋诡计是最棒的催情剂，绝命毒师性爱篇将完美展现。",
            //     videos: [ // 预告片放置第一位
            //         {
            //             cover: "",
            //             media: "https://akmaivideo.cdchuangao.com/wujushe/index.m3u8",
            //         },
            //         {
            //             cover: "",
            //             media: "https://akmaivideo.cdchuangao.com/wujushe/index.m3u8",
            //         },
            //         {
            //             cover: "",
            //             media: "https://akmaivideo.cdchuangao.com/wujushe/index.m3u8",
            //         },                  
            //         {
            //             cover: "",
            //             media: "https://akmaivideo.cdchuangao.com/wujushe/index.m3u8",
            //         },
            //         {
            //             cover: "",
            //             media: "https://akmaivideo.cdchuangao.com/wujushe/index.m3u8",
            //         }
            //     ]
            // },
        ]

    }

    initOptions(videoUrl, autoplay, muted){
        const options = {
            autoplay: autoplay, // 如果true,浏览器准备好时开始播放。
            muted: muted, // 默认情况下将会消除任何音频。
            loop: true, // 导致视频一结束就重新开始。
            preload: 'auto', // auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',  //汉化
            fluid: true, // 当true时，播放器将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [{
                type: 'application/x-mpegURL',
                src: videoUrl  //视频播放地址
            }],
            notSupportedMessage: '此视频暂无法播放，请稍后再试', // 无法播放媒体源时显示的默认信息。
            errorDisplay: false,  //错误显示
            posterImage: false,  //视频的预览海报图片显示
            bigPlayButton: false,  //视频中间的播放按钮显示
            textTrackDisplay: false
        };

        return options;
    };   

    checkSignInHandler(forms){
        if(!forms.email){
            return "请输入用户名";
        } else if (!forms.password){
            return "请输入密码";
        } else {
            return "success";
        }
    };

    checkSignUpHandler(forms){

        const valid_map = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;

        if(!forms.email){
            return "请输入用户名";
        } else if (!valid_map.test(forms.email)){
            return "请检查邮箱格式";
        } else if (!forms.password){
            return "请输入密码";
        }  else if (forms.password.length < 6 || forms.password.length > 12){
            return "请输入6-12位的密码";
        } else if (forms.password !== forms.rePassword){
            return "两次输入的密码不一致";
        } else {
            return "success";
        }
    };

    checkEditEmailHandler(forms){

        const valid_map = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;

        if(!forms.email){
            return "请输入用户名";
        } else if (!valid_map.test(forms.email)){
            return "请检查邮箱格式";
        } else {
            return "success";
        } 
    };

    checkEditPasswordHandler(forms){
        if(!forms.oldpassword){
            return "请输入旧密码";
        } else if (!forms.password){
            return "请输入新密码";
        } else if (forms.password.length < 6 || forms.password.length > 12){
            return "请输入6-12位的密码";
        } else if (forms.password !== forms.password_confirmation){
            return "两次输入的密码不一致";
        } else {
            return "success";
        }
    };
}

export const Utils = new UtilClass();